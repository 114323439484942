small {
  opacity: 0.6;
}
.text-muted {
  opacity: 0.6;
}
.clear {
  display: block;
  overflow: hidden;
}
.center {
  display: flex;
  justify-content: center;
  align-items: center;
}
.title {
  font-size: 16px;
  font-weight: bold;
}
.y-center {
  display: flex;
  align-items: center;
}
.block {
  display: block;
}
.inline {
  display: inline;
}
.flex-v {
  display: flex;
  flex-direction: column;
}
.flex-h {
  display: flex;
  flex-direction: row;
}
.none {
  display: none;
}
.b-white {
  border-color: #ffffff;
}
.w-full {
  width: 100%;
}
.w-80 {
  width: 80%;
}
.w-auto {
  width: auto;
}
.h-auto {
  height: auto;
}
.h-full {
  height: 100%;
}
.h-v {
  height: 100vh;
}
.h-v-5 {
  height: 50vh;
}
.pull-left {
  float: left;
}
.pull-right {
  float: right;
}
.w-40 {
  width: 40px;
  height: 40px;
  line-height: 40px;
  display: inline-block;
  text-align: center;
}
.clear-fix {
  clear: both;
}
.c-blue {
  color: #1890ff;
}
.c-text-gray {
  color: #424242;
}
.html-reset p {
  margin: 0;
}
.hide {
  display: none;
}
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}
::-webkit-scrollbar-thumb {
  background-color: #777;
}
.table-operations {
  margin-bottom: 16px;
}
.table-operations > button {
  margin-right: 8px;
}
.editable-row-text {
  padding: 5px;
}
.editable-row-operations a {
  margin-right: 8px;
}
.ques-table .ant-table-tbody > tr > td {
  max-width: 500px;
  max-height: 300px;
  overflow: hidden;
}
.login {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
.login .login-form {
  padding: 36px 0 0 0;
}
.login .login-form .login-logo {
  text-align: center;
  height: 40px;
  line-height: 40px;
  cursor: pointer;
  margin-bottom: 24px;
}
.login .login-form .login-logo span {
  vertical-align: text-bottom;
  font-size: 16px;
  text-transform: uppercase;
  display: inline-block;
}
.register {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
.register .register-form {
  padding: 36px 0 0 0;
}
.register .register-form .login-logo {
  text-align: center;
  height: 40px;
  line-height: 40px;
  cursor: pointer;
  margin-bottom: 24px;
}
.register .register-form .login-logo span {
  vertical-align: text-bottom;
  font-size: 16px;
  text-transform: uppercase;
  display: inline-block;
}
.installer {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 100px 100px 0;
  border-color: transparent #313653 transparent transparent;
  position: fixed;
  top: 0;
  right: 0;
}
.installer__btn {
  width: 50px;
  height: 50px;
  transform: rotate(45deg);
  left: 50px;
  position: absolute;
  background: url('imgs/installer.png') no-repeat;
  background-size: cover;
}
ul.icons-list {
  list-style: none;
  overflow: hidden;
}
ul.icons-list li {
  float: left;
  width: 10%;
  text-align: center;
  list-style: none;
  cursor: pointer;
  height: 100px;
  transition: all .3s;
  background-color: #fff;
}
ul.icons-list li:hover {
  background-color: #cccccc;
  color: #fff;
}
ul.icons-list li i {
  margin: 16px 0 10px;
}
ul.icons-list li span {
  display: block;
  text-align: center;
}
.ant-btn + .ant-btn {
  margin-left: 10px;
}
.vertical-center-modal {
  text-align: center;
  white-space: nowrap;
}
.vertical-center-modal:before {
  content: '';
  display: inline-block;
  height: 100%;
  vertical-align: middle;
  width: 0;
}
.vertical-center-modal .ant-modal {
  display: inline-block;
  vertical-align: middle;
  top: 0;
  text-align: left;
}
/*
// Use flex which not working in IE
.vertical-center-modal {
  display: flex;
  align-items: center;
  justify-content: center;
}

.vertical-center-modal .ant-modal {
  top: 0;
}
*/
.ant-menu-dark.ant-menu-inline .ant-menu-item-selected {
  background-color: #5f5f5f !important;
}
.custom-trigger {
  font-size: 18px;
  line-height: 64px;
  padding: 0 16px;
  cursor: pointer;
  transition: color 0.3s;
}
.ant-layout-sider-collapsed {
  overflow-y: initial !important;
}
.avatar {
  position: relative;
  display: inline-block;
  width: 80px;
  line-height: 1;
  border-radius: 500px;
  white-space: nowrap;
  font-weight: bold;
  color: #333;
}
.avatar .avatar-name {
  position: absolute;
  right: 20px;
  left: auto;
  top: auto;
  bottom: 0;
  text-align: right;
}
.avatar i {
  position: absolute;
  right: 0px;
  top: 0;
  width: 10px;
  height: 10px;
  margin: 1px;
  border-width: 2px;
  border-style: solid;
  border-radius: 100%;
  background-color: #6cc788;
}
.avatar i.bottom {
  left: auto;
  top: auto;
  bottom: 0;
  right: 0;
}
.avatar i.on {
  background-color: #6cc788;
}
.avatar img {
  border-radius: 500px;
  width: 100%;
}
.switcher {
  z-index: 1050;
  position: fixed;
  top: 78px;
  right: -240px;
  width: 240px;
  transition: right 0.2s ease;
  border: 1px solid rgba(120, 120, 120, 0.1);
  background-clip: padding-box;
}
.switcher.active {
  right: -2px;
}
.switcher .sw-btn {
  position: absolute;
  left: -43px;
  top: -1px;
  padding: 10px 15px;
  z-index: 1045;
  border: 1px solid rgba(120, 120, 120, 0.1);
  border-right-width: 0;
  background-clip: padding-box;
}
.home_banner {
  text-align: center;
  height: 300px;
  line-height: 300px;
  background: #000;
  overflow: hidden;
}
.react-draggable,
.cursor-move {
  cursor: move;
}
.react-draggable strong,
.cursor-move strong {
  background: #ddd;
  border: 1px solid #999;
  border-radius: 3px;
  display: block;
  margin-bottom: 10px;
  padding: 3px 5px;
  text-align: center;
}
.no-cursor {
  cursor: auto;
}
.card-tool {
  position: absolute;
  right: 24px;
  top: 24px;
}
.list-group .list-group-item {
  position: relative;
  display: block;
  margin-bottom: -1px;
  padding: 12px 16px;
  background: transparent;
  border: 1px solid #ddd;
  border-color: rgba(120, 130, 140, 0.065);
  border-width: 1px 0;
}
.list-group .list-group-item:first-child {
  border-top-width: 0;
}
.list-group .list-group-item:last-child {
  border-bottom-width: 0;
}
.no-padding .ant-card-body {
  padding: 0 !important;
}
img {
  vertical-align: middle;
}
.img-responsive {
  width: 100%;
  height: auto;
}
.img-circle {
  border-radius: 50%;
}
.text {
  font-size: 1rem;
}
.text-1-5x {
  font-size: 1.5rem;
}
.text-2x {
  font-size: 2rem;
}
.text-3x {
  font-size: 3rem;
}
.text-4x {
  font-size: 4rem;
}
.text-b {
  font-weight: 700;
}
.text-center {
  text-align: center;
}
.text-left {
  text-align: left;
}
.text-right {
  text-align: right;
}
.text-danger,
.text-danger-hover a:hover {
  color: #f44455 !important;
}
.text-dark,
.text-dark-hover a:hover {
  color: #2e3e4e !important;
}
.text-info,
.text-info-hover a:hover {
  color: #ff962a !important;
}
.text-success,
.text-success-hover a:hover {
  color: #6cc788 !important;
}
.text-blue,
.text-blue-hover a:hover {
  color: #2196f3 !important;
}
.text-gray,
.text-blue-hover a:hover {
  color: #424242 !important;
}
.text-b {
  font-weight: 700;
}
.dark-white {
  color: #2e3e4e;
  background-color: #ffffff;
}
.min-black {
  color: rgba(255, 255, 255, 0.87);
  background-color: rgba(0, 0, 0, 0.065);
}
.black {
  color: rgba(255, 255, 255, 0.87);
  background-color: #2a2b3c;
}
.dark {
  color: rgba(255, 255, 255, 0.87);
  background-color: #2e3e4e;
}
.grey {
  color: rgba(255, 255, 255, 0.87);
  background-color: #424242;
}
.primary {
  color: #1890ff;
  background-color: #1890ff;
}
.info {
  color: rgba(255, 255, 255, 0.87);
  background-color: #6887ff;
}
.warn {
  color: rgba(255, 255, 255, 0.87);
  background-color: #fcc100;
}
.danger {
  color: rgba(255, 255, 255, 0.87);
  background-color: #f44455;
}
.w-8 {
  width: 8px;
  height: 8px;
  display: inline-block;
}
.w-16 {
  width: 16px;
  height: 16px;
  display: inline-block;
}
.w-20 {
  width: 20px;
  height: 20px;
  display: inline-block;
}
.w-24 {
  width: 24px;
  height: 24px;
  display: inline-block;
  text-align: center;
}
.w-32 {
  width: 32px;
  height: 32px;
  line-height: 32px;
  display: inline-block;
  text-align: center;
}
.w-40 {
  width: 40px;
  height: 40px;
  line-height: 40px;
  display: inline-block;
  text-align: center;
}
.w-48 {
  width: 48px;
  height: 48px;
  line-height: 48px;
  display: inline-block;
  text-align: center;
}
.w-56 {
  width: 56px;
  height: 56px;
  line-height: 56px;
  display: inline-block;
  text-align: center;
}
.f-b {
  font-weight: bold;
}
.b-a {
  border: 1px solid rgba(120, 130, 140, 0.13);
}
.pb-s {
  padding-bottom: 0.5rem !important;
}
.pb-m {
  padding-bottom: 1rem !important;
}
.pb-l {
  padding-bottom: 2rem !important;
}
.pt-s {
  padding-top: 0.5rem !important;
}
.pt-m {
  padding-top: 1rem !important;
}
.pt-l {
  padding-top: 2rem !important;
}
.pa-s {
  padding: 0.5rem !important;
}
.pa-m {
  padding: 1rem !important;
}
.pa-l {
  padding: 2rem !important;
}
.mr-s {
  margin-right: 0.5rem !important;
}
.mr-m {
  margin-right: 1rem !important;
}
.mr-l {
  margin-right: 2rem !important;
}
.ml-s {
  margin-left: 0.5rem !important;
}
.ml-m {
  margin-left: 1rem !important;
}
.ml-l {
  margin-left: 2rem !important;
}
.mb-s {
  margin-bottom: 0.5rem !important;
}
.mb-m {
  margin-bottom: 1rem !important;
}
.mb-l {
  margin-bottom: 2rem !important;
}
.mt-s {
  margin-top: 0.5rem !important;
}
.mt-m {
  margin-top: 1rem !important;
}
.mt-l {
  margin-top: 2rem !important;
}
/*
 * File: app.less
 * Desc: 描述
 * File Created: 2020-07-26 18:27:37
 * Author: yezi
 * ------
 * Copyright 2020 - present, yezi
 */
.app_layout {
  flex-direction: column;
}
.app_layout_content {
  margin: 0 16px;
  overflow: initial;
  flex: 1 1 0;
}
.app_layout_foot {
  text-align: center;
}
.main {
  width: 100%;
  height: 100%;
}
.main .main-left {
  height: 100%;
  float: left;
}
.main .main-content {
  float: left;
  margin-left: 10px;
  height: 100%;
}
.main .main-right {
  float: left;
  margin-left: 10px;
  height: 100%;
}
.main .main-bottom {
  display: flex;
  justify-content: flex-end;
  padding: 10px 15px;
  text-align: right;
  height: auto;
}
.imgbtn_box {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.imgbtn_box .img {
  width: 85px;
  height: 85px;
}
.imgbtn_box .title {
  font-size: 18px;
  color: #010101;
  text-align: center;
  margin-top: 2px;
  font-weight: 400;
}
.imgbtn_box .detail {
  font-size: 14px;
  color: #757575;
  text-align: center;
  margin-top: 20px;
}
.course-box {
  margin-bottom: 14px;
}
.course-box.live {
  position: relative;
}
.course-box .img {
  width: 223px;
  height: 168px;
  background-color: #ccc;
}
.course-box .big-img {
  width: 466px;
  height: 383px;
  background-color: #ccc;
}
.course-box .img + .title {
  width: 223px;
}
.course-box .big-img + .title {
  width: 466px;
}
.course-box .title {
  font-size: 18px;
  color: #080606;
  margin-top: 8px;
  font-weight: 400;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  display: -moz-box;
  -moz-line-clamp: 1;
  -moz-box-orient: vertical;
  overflow-wrap: break-word;
  word-break: break-all;
  white-space: normal;
  overflow: hidden;
}
.teacher_box {
  display: flex;
  flex-direction: column;
  width: 100%;
  float: left;
  background-color: #e7e7e7;
  padding: 15px 10px;
}
.teacher_box .header {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
}
.teacher_box .header .img {
  width: 80px;
  height: 80px;
  background-color: #ccc;
  margin-bottom: 10px;
}
.teacher_box .header .title {
  font-size: 14px;
  color: #010101;
  text-align: center;
}
.teacher_box .header .sub_title {
  font-size: 14px;
  font-weight: 300;
  color: #333333;
  margin-left: 10px;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  display: -moz-box;
  -moz-line-clamp: 1;
  -moz-box-orient: vertical;
  overflow-wrap: break-word;
  word-break: break-all;
  white-space: normal;
  overflow: hidden;
}
.teacher_box .detail {
  font-size: 14px;
  color: #757575;
  text-align: center;
  text-overflow: ellipsis;
  min-height: 42px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  display: -moz-box;
  -moz-line-clamp: 2;
  -moz-box-orient: vertical;
  overflow-wrap: break-word;
  word-break: break-all;
  white-space: normal;
  overflow: hidden;
}
.course-detail-header {
  background-color: #191919;
  display: flex;
  justify-content: center;
}
.course-detail-header .cd-header-content {
  width: 1200px;
  height: 100%;
  display: flex;
}
.course-detail-header .course-video {
  width: 850px;
  height: auto;
}
.course-detail-header .course-chapters {
  flex: 1;
  padding: 15px 10px;
}
.course-detail-header .course-chapters .ant-card {
  color: #ccc;
  background: #2d2d2d;
  border-radius: 4px;
}
.course-detail-header .course-chapters .ant-card .chapter-title,
.course-detail-header .course-chapters .ant-card .chapter-attach {
  color: #ccc;
}
.course-detail-header .course-chapters .ant-card .disable {
  color: #969793;
}
.course-detail-header .course-chapters .ant-card-head {
  color: #ccc;
  border-bottom: 2px solid #3c3c3c;
}
.course-detail-header .course-chapters .ant-card-bordered {
  border: 1px solid #2d2d2d;
}
.course-detail-header .course-chapters .cc-card-body {
  font-size: 14px;
  line-height: 18px;
}
.course-detail-header .course-chapters .cc-card-body .text {
  margin-top: 5px;
  font-weight: 400;
}
.course-detail-header .course-chapters .cc-card-body .price {
  font-size: 30px;
  font-weight: bold;
}
.course_detail_body {
  background-color: #fff;
  padding: 15px 20px;
}
.course_detail_body .course-desc {
  margin-top: 10px;
  color: #3c3c3c;
}
.uc_header {
  width: 100%;
  height: 132px;
  background: linear-gradient(90deg, #2bb45c 0%, #64c354 100%);
}
.uc_header .uc_header_content {
  width: 1200px;
  margin: auto;
  padding: 15px 0px;
  color: white;
  display: flex;
  justify-content: space-between;
}
.uc_header .uc_header_content .name {
  font-size: 18px;
}
.uc_header .uc_header_content .company {
  font-size: 18px;
}
.uc_header .uc_header_content .point {
  font-size: 14px;
}
.border_gray {
  border: 1px solid #dddddd;
}
.bg_white {
  background-color: #fff;
}
.stu_center .slider {
  min-height: 200px;
  padding: 8px 0px;
  margin-left: 15px;
  border-radius: 2px;
  background-color: #fff;
}
.stu_center .slider .slider_item {
  padding: 8px 15px;
  margin-bottom: 5px;
  cursor: pointer;
}
.stu_center .slider .slider_item.active {
  color: #0d953d;
  border-left: #0d953d 2px solid;
}
.stu_center .slider .slider_item.normal {
  color: #080606;
}
.course-cell {
  color: #080606;
  font-size: 14px;
}
.course-cell .course-title {
  font-size: 15px;
  font-weight: 700;
  font-size: 14px;
}
.chapter-cell {
  font-size: 14px;
  margin-left: 15px;
}
.chapter-cell .chapter-title {
  font-size: 14px;
  font-weight: 500;
  color: #080606;
}
.chapter-cell .chapter-attach {
  color: #080606;
}
.chapter-cell .chapter-attach.disable {
  color: #969793;
}
.cursor-p {
  cursor: pointer;
}
.order-cell .order-content {
  display: flex;
  justify-content: space-between;
}
.order-cell .order-content .left {
  display: flex;
  align-items: center;
}
.order-cell .order-content .left .img {
  width: 90px;
  height: 50px;
  background-color: #ccc;
}
.order-cell .order-content .left .title {
  margin-left: 10px;
  font-weight: 500;
}
.order-cell .order-content .right {
  display: flex;
  align-items: center;
}
.text-yellow {
  color: #ff6923;
}
.text-green {
  color: #23bb58;
}
.text-red {
  color: #ff0000;
}
.text-gray {
  color: #68686d;
}
.home-hot-panel > .panel-header {
  margin-bottom: 15px;
}
.home-hot-panel > .panel-header > .title {
  font-size: 18px;
  font-weight: 700;
  color: #333333;
}
.home-hot-panel > .panel-body {
  margin-bottom: 15px;
  float: left;
  width: 100%;
}
.home-hot-panel > .panel-body .course-box {
  display: inline-block;
  vertical-align: top;
  float: left;
}
.home-search {
  display: flex;
  width: 100%;
  justify-content: center;
  background-color: #e5eff9;
  padding: 15px 20px;
}
.home-category {
  display: flex;
  justify-content: center;
  margin: 20px 0;
  background-color: #fff9f4;
  padding: 15px 20px;
}
.home-category .category-item {
  padding: 16px;
  background-color: #fff;
  margin: 0 10px;
  width: 400px;
  position: relative;
  cursor: pointer;
}
.home-category .category-item .category-item-content {
  height: 100%;
  width: 100%;
}
.home-category .category-item .category-item-content .category-title {
  font-size: 16px;
  font-weight: bold;
  color: #111111;
  margin-bottom: 10px;
}
.home-category .category-item:after {
  background-position: 100% 100%;
  content: ' ';
  width: 90px;
  height: 90px;
  position: absolute;
  right: 0;
  bottom: 0;
}
.home-category .category-item:nth-child(1):after,
.home-category .category-item:nth-child(4):after {
  background: url(../style/imgs/home_cate_blue.png) no-repeat 5px 5px;
}
.home-category .category-item:nth-child(2):after {
  background: url(../style/imgs/home_cate_purple.png) no-repeat 5px 5px;
}
.home-category .category-item:nth-child(3):after {
  background: url(../style/imgs/home_cate_green.png) no-repeat 5px 5px;
}
.home-category .category-item:nth-child(1) a,
.home-category .category-item:nth-child(1),
.home-category .category-item:nth-child(4),
.home-category .category-item:nth-child(4) a {
  background-color: #def9ff;
  color: #008dea;
}
.home-category .category-item:nth-child(2),
.home-category .category-item:nth-child(2) a,
.home-category .category-item:nth-child(5),
.home-category .category-item:nth-child(5) a {
  background-color: #efedff;
  color: #6000ff;
}
.home-category .category-item:nth-child(3),
.home-category .category-item:nth-child(3) a {
  background-color: #e8fffd;
  color: #1fa574;
}
.clear-fix {
  clear: both;
}
.tips {
  color: red;
  font-size: 12px;
}
.play-container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}
.play-container .disable {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  color: #fff;
  font-size: 18px;
  left: 0;
  top: 0;
  width: 100%;
  line-height: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}
.expert-detail .img {
  width: 150px;
  height: 150px;
}
.expert-detail .header-left {
  margin-left: 50px;
}
.expert-detail .header-right {
  margin-top: 20px;
  width: 500px;
}
.policyCode-detail .header-center {
  display: flex;
  align-items: center;
  justify-content: center;
}
.live-status {
  position: absolute;
  top: 10px;
}
.live-status.unstart {
  right: 10px;
}
.live-status.started {
  left: 10px;
}
.live-status.finished {
  right: 10px;
}
.footer {
  padding: 20px 20px;
  color: #4b4b4b;
  font-size: 14px;
}
.pdf-fullscreen {
  position: absolute;
  z-index: 100;
  right: 20px;
  bottom: 20px;
  width: 40px;
  height: 40px;
  background: url('imgs/fullscreen-expand.png') no-repeat;
  background-size: 100%;
}
body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}
#root {
  width: 100%;
  height: 100%;
}
.ant-layout {
  height: auto;
}
.ant-layout .ant-layout-sider-collapsed .anticon {
  font-size: 16px;
}
.ant-layout .ant-layout-sider-collapsed .nav-text {
  display: none;
}
.ant-layout .ant-layout-sider-collapsed .ant-menu-submenu-vertical > .ant-menu-submenu-title:after {
  display: none;
}
.ant-layout .ant-layout-sider-collapsed .ant-menu-dark:not(.ant-menu-inline) .ant-menu-submenu-open {
  color: inherit;
}
.ant-layout p {
  margin: 10px 0 10px 0;
}
.gutter-example .ant-row > div {
  background: transparent;
  border: 0;
}
.gutter-box {
  padding: 5px 0;
}
:global .ant-card-head-title {
  font-size: 14px !important;
}
.flex {
  display: flex;
}
.flex-h-end {
  display: flex;
  justify-content: flex-end;
}
.flex-h-bottom {
  display: flex;
  align-items: flex-end;
}
.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}
.flex-hcenter {
  display: flex;
  justify-content: center;
}
.flex-vcenter {
  display: flex;
  align-items: center;
}
.flex-h-end {
  display: flex;
  justify-content: flex-end;
}
.flex-h-around {
  display: flex;
  justify-content: space-around;
}
.flex-h-between {
  display: flex;
  justify-content: space-between;
}
.flex-1 {
  flex: 1;
}
.flex-2 {
  flex: 2;
}
.flex-wrapper {
  display: flex;
  flex-wrap: wrap;
}
.border-bottom {
  border-bottom: 1px solid #eee;
}
.w-h-100 {
  width: 100%;
  height: 100%;
}
.video-react .video-react-big-play-button {
  top: calc(49.25%) !important;
  left: calc(47%) !important;
}
.text-label {
  font-size: 14px;
  font-weight: 400;
  color: #949494;
  margin-right: 20px;
}
.text-white {
  color: #ccc;
}
